import Vue from 'vue';
import StackdriverErrorReporter from 'stackdriver-errors-js';

export default ({ $config }, inject) => {
  const projectId = $config.googleProjectId;
  const googleErrorReportingKey = $config.googleErrorReportingKey;

  let errorHandler;

  try {
    errorHandler = new StackdriverErrorReporter();
    errorHandler.start({
      key: googleErrorReportingKey,
      projectId: projectId,
      service: 'frontend',
      reportUncaughtExceptions: process.env.ENV_TYPE !== 'local',
      reportUnhandledPromiseRejections: process.env.ENV_TYPE !== 'local',
      disabled: process.env.ENV_TYPE === 'local',
    });
  } catch (e) {
    console.error('unable to initilize error reporting', e);
    return;
  }

  if (errorHandler) {
    Vue.config.errorHandler = error => {
      if (error) {
        errorHandler.report(error);
        console.error(error);
      }
    };

    window.onerror = function(error) {
      errorHandler.report(error);
      console.error(error);
    };
  }

  inject('stackdriver', {
    report(error) {
      if (errorHandler) {
        errorHandler.report(error);
        console.error(error);
      }
    },
  });
};
