//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import { mapGetters, mapActions } from 'vuex';
import sharedVariables from '~/util/shared-variables.js';
import LogoIcon from '~/static/images/bowens-logo.svg';
import LogoIconMobile from '~/static/images/bowensnewlogo.svg';
import ArrowIcon from '~/static/svg/arrow-right.svg';
import BackIcon from '~/static/svg/arrow-left-white.svg';
import LocationSvg from '~/static/svg/location-blue.svg';
import TradeIcon from '~/static/svg/trade-blue.svg';
import UserIcon from '~/static/svg/user-blue.svg';
import AddToQuotesIcon from '~/static/svg/add-to-quotes.svg';
import DigitalCardIcon from '~/static/svg/digital-card.svg';

const MEGA_MENU = 'mega-menu';

export default {
  components: {
    LogoIcon,
    LogoIconMobile,
    ArrowIcon,
    LocationSvg,
    UserIcon,
    AddToQuotesIcon,
    TradeIcon,
    DigitalCardIcon,
    BackIcon,
  },
  data() {
    return {
      showMenu: false,
      showModal: false,
      scrolled: false,
      showMegaMenu: false,
      canShowMegaMenu: true,
      isShroudOpen: false,
      showLoginPopup: false,
      searchClient: undefined,
      indexName: undefined,
    };
  },
  watch: {
    $route() {
      this.homepageOnly = this.$router.currentRoute.path === '/';
    },
    showMenu(newValue) {
      newValue ? this.showShroud() : this.hideShroud();
    },
    scrolled(s) {
      s
        ? this.$refs.header.classList.add('header--scrolled')
        : this.$refs.header.classList.remove('header--scrolled');
    },
  },
  computed: {
    ...mapGetters({
      cmsSettings: 'settings/cms',
      getIsSearchBarVisible: 'layout/getIsSearchBarVisible',
      getIsLoggedIn: 'users/getIsLoggedIn',
      getTotalJobLists: 'joblist/getTotalJobLists',
      getCustomer: 'users/getCustomer',
      getUser: 'users/getUser',
    }),
    customerType() {
      if (this.getCustomer) {
        const customer = this.getCustomer;

        if (customer.typeAcctAr === 'A') {
          return true;
        } else {
          return false;
        }
      }
    },
    customerName() {
      if (this.getUser) {
        if (this.getUser.contact) {
          const userContact = this.getUser.contact;

          return userContact.name;
        } else {
          return '';
        }
      }
    },
    companyName() {
      if (this.getCustomer) {
        const customer = this.getCustomer;

        if (customer.name) {
          return customer.name;
        } else {
          return '';
        }
      }
    },
    customerId() {
      if (this.getUser) {
        if (this.getUser.statement) {
          const userStatement = this.getUser.statement;

          return `${userStatement.customerId}`;
        } else {
          return '';
        }
      }
    },
    canUseJobListFeatures() {
      return this.$config.featureToggle.toggles.enableJobListFeature;
    },
    canShowBackIcon() {
      if (window.navigator.standalone || this.isRunningStandalone()) {
        return true;
      }

      return false;
    },
    isHomepage() {
      if (this.$route.path === '/') return true;
      return false;
    },
    mobileMenu() {
      if (
        this.cmsSettings &&
        this.cmsSettings.menus &&
        this.cmsSettings.menus.main
      ) {
        return this.cmsSettings.menus.main;
      }
      return [];
    },
    current() {
      let path = this.$route.path;
      if (path.charAt(path.length - 1) !== '/') path += '/';
      return this.$store.getters['sitemap/getPage'](path);
    },
    matchBlogType() {
      if (this.current) {
        return (
          this.current.type === 'archive-blog' ||
          this.current.type === 'category-blog' ||
          this.current.type === 'single-blog'
        );
      }
      return false;
    },
    isSearchBarVisible() {
      if (this.$route.path === '/search/') return false;
      return this.getIsSearchBarVisible();
    },
  },
  beforeMount() {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.shouldShowMegaMenu();

    this.indexName = this.$config.algoliaIndex;
    this.searchClient = this.getAlgoliaSearchClient({
      applicationId: this.$config.algoliaApplicationId,
      key: this.$config.algoliaSearchKey,
    });
  },
  methods: {
    ...mapActions({
      getAlgoliaSearchClient: 'search/getAlgoliaSearchClient',
      fetchForm: 'gforms/fetchForm',
    }),
    isRunningStandalone() {
      return window.matchMedia('(display-mode: standalone)').matches;
    },
    checkingActiveState(item) {
      if (this.current) {
        if (this.matchBlogType && item.url.includes('/blog')) {
          return true; // Blog
        } else if (
          this.current.type === 'product-category' &&
          item.url === '#mega-menu'
        ) {
          return true; // Mega menu
        } else if (
          this.current.url.includes('/services/') &&
          item.url === '#services'
        ) {
          return true; // Services
        }
      }
      return false;
    },
    showShroud() {
      this.isShroudOpen = true;
    },
    hideShroud() {
      clearAllBodyScrollLocks();
      this.isShroudOpen = false;
    },
    dismissOthers(index = 1) {
      if (index !== 0) {
        this.showMenu = false;
        this.showMegaMenu = false;
        this.hideShroud();
      }
    },
    toggleMenuItemHover(item, toggle) {
      if (item.custom_class === MEGA_MENU) {
        if (toggle === true) {
          this.openMegaMenu();
          return;
        } else if (this.isOnMegaMenu() === null) {
          this.dismissMegaMenu();
          return;
        }
      } else if (!this.showMegaMenu) {
        item.children && toggle ? this.showShroud() : this.hideShroud();
      }
    },
    toggleMegaMenu(index, event = null) {
      if (index === 0) {
        if (event) event.preventDefault();
        this.showMegaMenu = !this.showMegaMenu;
      }
      if (this.showMegaMenu) this.showShroud();
      else this.hideShroud();
    },
    openMegaMenu() {
      this.showMegaMenu = true;
      this.showShroud();
    },
    dismissMegaMenu() {
      this.showMegaMenu = false;
      this.hideShroud();
    },
    isOnMegaMenu() {
      return (
        this.$el.querySelector('.mega-menu:hover') ||
        this.$el.querySelector('.header__nav-item:first-child:hover')
      );
    },
    toggleSubmenu() {
      this.showMenu = !this.showMenu;
    },
    toggleBack() {
      this.$router.go(-1);
    },
    openMenu() {
      this.openMegaMenu();
      this.showMenu = true;
      this.$refs.hamburger.classList.add('active');
    },
    handleScroll() {
      this.scrolled = window.scrollY > 30;
    },
    shouldShowMegaMenu() {
      if (window.innerWidth < sharedVariables.lg_min) {
        this.canShowMegaMenu = false;
      } else {
        this.canShowMegaMenu = true;
      }
    },
    handleResize() {
      this.shouldShowMegaMenu();
    },
    dismissMenu() {
      this.showMenu = false;
      this.hideShroud();
    },
    async search(payload) {
      let path = '/search/';

      this.$router.push({
        path,
        query: payload,
      });
    },
    toggleMyCard() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  },
};
