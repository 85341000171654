import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5e08f79a = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _2ec68c0e = () => interopDefault(import('../pages/my-account/index.vue' /* webpackChunkName: "pages/my-account/index" */))
const _09781fc2 = () => interopDefault(import('../pages/stores/index.vue' /* webpackChunkName: "pages/stores/index" */))
const _a4513db8 = () => interopDefault(import('../pages/c/utils.js' /* webpackChunkName: "pages/c/utils" */))
const _005c5ecf = () => interopDefault(import('../pages/checkout/cart.vue' /* webpackChunkName: "pages/checkout/cart" */))
const _c5cbdbba = () => interopDefault(import('../pages/checkout/delivery.vue' /* webpackChunkName: "pages/checkout/delivery" */))
const _1434713a = () => interopDefault(import('../pages/checkout/details.vue' /* webpackChunkName: "pages/checkout/details" */))
const _cab9b16c = () => interopDefault(import('../pages/checkout/login.vue' /* webpackChunkName: "pages/checkout/login" */))
const _133b8527 = () => interopDefault(import('../pages/checkout/payment.vue' /* webpackChunkName: "pages/checkout/payment" */))
const _9d30a790 = () => interopDefault(import('../pages/checkout/pending.vue' /* webpackChunkName: "pages/checkout/pending" */))
const _9c4d16c6 = () => interopDefault(import('../pages/checkout/thank-you.vue' /* webpackChunkName: "pages/checkout/thank-you" */))
const _20d81678 = () => interopDefault(import('../pages/invoices/confirmation.vue' /* webpackChunkName: "pages/invoices/confirmation" */))
const _797c1fee = () => interopDefault(import('../pages/invoices/error.vue' /* webpackChunkName: "pages/invoices/error" */))
const _7de9dc50 = () => interopDefault(import('../pages/invoices/pending.vue' /* webpackChunkName: "pages/invoices/pending" */))
const _6639f23d = () => interopDefault(import('../pages/invoices/thank-you.vue' /* webpackChunkName: "pages/invoices/thank-you" */))
const _6cd99477 = () => interopDefault(import('../pages/my-account/account-manager/index.vue' /* webpackChunkName: "pages/my-account/account-manager/index" */))
const _002d5470 = () => interopDefault(import('../pages/my-account/admin-permissions/index.vue' /* webpackChunkName: "pages/my-account/admin-permissions/index" */))
const _0bc69cc3 = () => interopDefault(import('../pages/my-account/balances/index.vue' /* webpackChunkName: "pages/my-account/balances/index" */))
const _436126ec = () => interopDefault(import('../pages/my-account/e-invoicing/index.vue' /* webpackChunkName: "pages/my-account/e-invoicing/index" */))
const _567cc85c = () => interopDefault(import('../pages/my-account/invoices/index.vue' /* webpackChunkName: "pages/my-account/invoices/index" */))
const _35f04c82 = () => interopDefault(import('../pages/my-account/link-login/index.vue' /* webpackChunkName: "pages/my-account/link-login/index" */))
const _160a50da = () => interopDefault(import('../pages/my-account/login/index.vue' /* webpackChunkName: "pages/my-account/login/index" */))
const _742ab874 = () => interopDefault(import('../pages/my-account/magic-link/index.vue' /* webpackChunkName: "pages/my-account/magic-link/index" */))
const _27fa4951 = () => interopDefault(import('../pages/my-account/orders/index.vue' /* webpackChunkName: "pages/my-account/orders/index" */))
const _5b860086 = () => interopDefault(import('../pages/my-account/pay-my-account/index.vue' /* webpackChunkName: "pages/my-account/pay-my-account/index" */))
const _42dfe963 = () => interopDefault(import('../pages/my-account/quotes/index.vue' /* webpackChunkName: "pages/my-account/quotes/index" */))
const _458bedc5 = () => interopDefault(import('../pages/my-account/reset-password/index.vue' /* webpackChunkName: "pages/my-account/reset-password/index" */))
const _ac3bdd50 = () => interopDefault(import('../pages/my-account/select-company/index.vue' /* webpackChunkName: "pages/my-account/select-company/index" */))
const _43eeefe0 = () => interopDefault(import('../pages/my-account/statements/index.vue' /* webpackChunkName: "pages/my-account/statements/index" */))
const _52c9d81c = () => interopDefault(import('../pages/my-account/two-factor/index.vue' /* webpackChunkName: "pages/my-account/two-factor/index" */))
const _15921fb7 = () => interopDefault(import('../pages/p/utils.js' /* webpackChunkName: "pages/p/utils" */))
const _9a706c4c = () => interopDefault(import('../pages/quotes/error.vue' /* webpackChunkName: "pages/quotes/error" */))
const _32fc892e = () => interopDefault(import('../pages/quotes/pending.vue' /* webpackChunkName: "pages/quotes/pending" */))
const _08ac7b8e = () => interopDefault(import('../pages/quotes/thank-you.vue' /* webpackChunkName: "pages/quotes/thank-you" */))
const _12011530 = () => interopDefault(import('../pages/checkout/zip/processing.vue' /* webpackChunkName: "pages/checkout/zip/processing" */))
const _739b36b2 = () => interopDefault(import('../pages/my-account/pay-my-account/invoice-payments.vue' /* webpackChunkName: "pages/my-account/pay-my-account/invoice-payments" */))
const _3bc78568 = () => interopDefault(import('../pages/my-account/quotes/pending.vue' /* webpackChunkName: "pages/my-account/quotes/pending" */))
const _9cfac922 = () => interopDefault(import('../pages/my-account/quotes/quotes-payment.vue' /* webpackChunkName: "pages/my-account/quotes/quotes-payment" */))
const _23a7c1cd = () => interopDefault(import('../pages/my-account/quotes/thank-you.vue' /* webpackChunkName: "pages/my-account/quotes/thank-you" */))
const _413c8642 = () => interopDefault(import('../pages/quotes/zip/processing.vue' /* webpackChunkName: "pages/quotes/zip/processing" */))
const _48038600 = () => interopDefault(import('../pages/my-account/quotes/zip/processing.vue' /* webpackChunkName: "pages/my-account/quotes/zip/processing" */))
const _45d23a30 = () => interopDefault(import('../pages/quote-reminder/unsubscribe/_transactionNo.vue' /* webpackChunkName: "pages/quote-reminder/unsubscribe/_transactionNo" */))
const _2c420c0a = () => interopDefault(import('../pages/track-order/all/_order.vue' /* webpackChunkName: "pages/track-order/all/_order" */))
const _24548331 = () => interopDefault(import('../pages/p/_slug.vue' /* webpackChunkName: "pages/p/_slug" */))
const _4e5eaad4 = () => interopDefault(import('../pages/quotes/_quoteId.vue' /* webpackChunkName: "pages/quotes/_quoteId" */))
const _2877501e = () => interopDefault(import('../pages/resume-cart/_id.vue' /* webpackChunkName: "pages/resume-cart/_id" */))
const _14fd6e47 = () => interopDefault(import('../pages/stores/_id.vue' /* webpackChunkName: "pages/stores/_id" */))
const _be60afd2 = () => interopDefault(import('../pages/track-order/_orderId.vue' /* webpackChunkName: "pages/track-order/_orderId" */))
const _c642e600 = () => interopDefault(import('../pages/invoices/_custId/_invoiceNo.vue' /* webpackChunkName: "pages/invoices/_custId/_invoiceNo" */))
const _bed06a8e = () => interopDefault(import('../pages/c/_.vue' /* webpackChunkName: "pages/c/_" */))
const _23fa3bb2 = () => interopDefault(import('../pages/joblist-enquiry/_.vue' /* webpackChunkName: "pages/joblist-enquiry/_" */))
const _2a94689e = () => interopDefault(import('../pages/joblist/_.vue' /* webpackChunkName: "pages/joblist/_" */))
const _221b1c40 = () => interopDefault(import('../pages/quote-confirmation/_.vue' /* webpackChunkName: "pages/quote-confirmation/_" */))
const _f37865bc = () => interopDefault(import('../pages/search/_.vue' /* webpackChunkName: "pages/search/_" */))
const _577b8236 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/checkout",
    component: _5e08f79a,
    name: "checkout___au"
  }, {
    path: "/my-account",
    component: _2ec68c0e,
    name: "my-account___au"
  }, {
    path: "/stores",
    component: _09781fc2,
    name: "stores___au"
  }, {
    path: "/c/utils",
    component: _a4513db8,
    name: "c-utils___au"
  }, {
    path: "/checkout/cart",
    component: _005c5ecf,
    name: "checkout-cart___au"
  }, {
    path: "/checkout/delivery",
    component: _c5cbdbba,
    name: "checkout-delivery___au"
  }, {
    path: "/checkout/details",
    component: _1434713a,
    name: "checkout-details___au"
  }, {
    path: "/checkout/login",
    component: _cab9b16c,
    name: "checkout-login___au"
  }, {
    path: "/checkout/payment",
    component: _133b8527,
    name: "checkout-payment___au"
  }, {
    path: "/checkout/pending",
    component: _9d30a790,
    name: "checkout-pending___au"
  }, {
    path: "/checkout/thank-you",
    component: _9c4d16c6,
    name: "checkout-thank-you___au"
  }, {
    path: "/invoices/confirmation",
    component: _20d81678,
    name: "invoices-confirmation___au"
  }, {
    path: "/invoices/error",
    component: _797c1fee,
    name: "invoices-error___au"
  }, {
    path: "/invoices/pending",
    component: _7de9dc50,
    name: "invoices-pending___au"
  }, {
    path: "/invoices/thank-you",
    component: _6639f23d,
    name: "invoices-thank-you___au"
  }, {
    path: "/my-account/account-manager",
    component: _6cd99477,
    name: "my-account-account-manager___au"
  }, {
    path: "/my-account/admin-permissions",
    component: _002d5470,
    name: "my-account-admin-permissions___au"
  }, {
    path: "/my-account/balances",
    component: _0bc69cc3,
    name: "my-account-balances___au"
  }, {
    path: "/my-account/e-invoicing",
    component: _436126ec,
    name: "my-account-e-invoicing___au"
  }, {
    path: "/my-account/invoices",
    component: _567cc85c,
    name: "my-account-invoices___au"
  }, {
    path: "/my-account/link-login",
    component: _35f04c82,
    name: "my-account-link-login___au"
  }, {
    path: "/my-account/login",
    component: _160a50da,
    name: "my-account-login___au"
  }, {
    path: "/my-account/magic-link",
    component: _742ab874,
    name: "my-account-magic-link___au"
  }, {
    path: "/my-account/orders",
    component: _27fa4951,
    name: "my-account-orders___au"
  }, {
    path: "/my-account/pay-my-account",
    component: _5b860086,
    name: "my-account-pay-my-account___au"
  }, {
    path: "/my-account/quotes",
    component: _42dfe963,
    name: "my-account-quotes___au"
  }, {
    path: "/my-account/reset-password",
    component: _458bedc5,
    name: "my-account-reset-password___au"
  }, {
    path: "/my-account/select-company",
    component: _ac3bdd50,
    name: "my-account-select-company___au"
  }, {
    path: "/my-account/statements",
    component: _43eeefe0,
    name: "my-account-statements___au"
  }, {
    path: "/my-account/two-factor",
    component: _52c9d81c,
    name: "my-account-two-factor___au"
  }, {
    path: "/p/utils",
    component: _15921fb7,
    name: "p-utils___au"
  }, {
    path: "/quotes/error",
    component: _9a706c4c,
    name: "quotes-error___au"
  }, {
    path: "/quotes/pending",
    component: _32fc892e,
    name: "quotes-pending___au"
  }, {
    path: "/quotes/thank-you",
    component: _08ac7b8e,
    name: "quotes-thank-you___au"
  }, {
    path: "/checkout/zip/processing",
    component: _12011530,
    name: "checkout-zip-processing___au"
  }, {
    path: "/my-account/pay-my-account/invoice-payments",
    component: _739b36b2,
    name: "my-account-pay-my-account-invoice-payments___au"
  }, {
    path: "/my-account/quotes/pending",
    component: _3bc78568,
    name: "my-account-quotes-pending___au"
  }, {
    path: "/my-account/quotes/quotes-payment",
    component: _9cfac922,
    name: "my-account-quotes-quotes-payment___au"
  }, {
    path: "/my-account/quotes/thank-you",
    component: _23a7c1cd,
    name: "my-account-quotes-thank-you___au"
  }, {
    path: "/quotes/zip/processing",
    component: _413c8642,
    name: "quotes-zip-processing___au"
  }, {
    path: "/my-account/quotes/zip/processing",
    component: _48038600,
    name: "my-account-quotes-zip-processing___au"
  }, {
    path: "/quote-reminder/unsubscribe/:transactionNo?",
    component: _45d23a30,
    name: "quote-reminder-unsubscribe-transactionNo___au"
  }, {
    path: "/track-order/all/:order?",
    component: _2c420c0a,
    name: "track-order-all-order___au"
  }, {
    path: "/p/:slug?",
    component: _24548331,
    name: "p-slug___au"
  }, {
    path: "/quotes/:quoteId?",
    component: _4e5eaad4,
    name: "quotes-quoteId___au"
  }, {
    path: "/resume-cart/:id?",
    component: _2877501e,
    name: "resume-cart-id___au"
  }, {
    path: "/stores/:id",
    component: _14fd6e47,
    name: "stores-id___au"
  }, {
    path: "/track-order/:orderId?",
    component: _be60afd2,
    name: "track-order-orderId___au"
  }, {
    path: "/invoices/:custId?/:invoiceNo?",
    component: _c642e600,
    name: "invoices-custId-invoiceNo___au"
  }, {
    path: "/c/*",
    component: _bed06a8e,
    name: "c-all___au"
  }, {
    path: "/joblist-enquiry/*",
    component: _23fa3bb2,
    name: "joblist-enquiry-all___au"
  }, {
    path: "/joblist/*",
    component: _2a94689e,
    name: "joblist-all___au"
  }, {
    path: "/quote-confirmation/*",
    component: _221b1c40,
    name: "quote-confirmation-all___au"
  }, {
    path: "/search/*",
    component: _f37865bc,
    name: "search-all___au"
  }, {
    path: "/*",
    component: _577b8236,
    name: "all___au"
  }],

  parseQuery: function(queryString) {
      return require('qs').parse(queryString);
    },
  stringifyQuery: function(object) {
      var queryString = require('qs').stringify(object, {
        encodeValuesOnly: true,
      });
      return queryString ? '?' + queryString : '';
    },
  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
