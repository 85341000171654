//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import translate from '~/mixins/translate';

export default {
  name: 'BasePricing',
  mixins: [translate],
  props: {
    sku: {
      type: String,
      required: true,
    },
    basePricing: {
      type: Object,
      required: true,
    },
    priceQuery: {
      type: Object,
      required: true,
    },
    customPricing: {
      type: Object,
      required: false,
    },
    usePromotionPrices: {
      type: Boolean,
      required: false,
    },
    showUom: {
      type: Boolean,
      default: true,
    },
    currentQty: {
      type: Number,
      required: false,
      default: 1,
    },
    selectedUom: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      customPricing: undefined,
      fromLabel: this.$t('product.from'),
    };
  },
  computed: {
    hasBasePricing() {
      return (
        this.basePricing &&
        this.isValidPriceNumber(this.basePricing.skuRetailIncGst)
      );
    },
    hasDefaultPricing() {
      return this.hasBasePricing || this.hasLowestPrice;
    },
    hasCustomPricing() {
      return (
        this.customPricing &&
        this.customPricing.unitPrice &&
        this.isValidPriceNumber(this.customPricing.unitPrice)
      );
    },
    hasPromotionPricing() {
      // user is not logged in, but this is a clearance product with a promotino price
      return (
        this.usePromotionPrices &&
        this.basePricing &&
        this.basePricing.promotion &&
        this.isValidPriceNumber(this.basePricing.promotion.plusGst)
      );
    },
    hasLowestPrice() {
      if (!this.basePricing?.webProductLowest) {
        return false;
      }

      return this.isValidPriceNumber(this.basePricing.webProductLowest.price);
    },
    hasHighestPrice() {
      if (!this.basePricing?.webProductHighest) {
        return false;
      }

      return this.isValidPriceNumber(this.basePricing.webProductHighest.price);
    },
    hasRangePrice() {
      if (!this.hasLowestPrice || !this.hasHighestPrice) {
        return false;
      }

      const lowest = this.basePricing.webProductLowest.price;
      const highest = this.basePricing.webProductHighest.price;

      return lowest !== highest;
    },
    findBiggestVolumeDiscount() {
      if (this.currentQty <= 1 || !this.bulkPricingInSelectedUom.length) {
        return undefined;
      }
      const discounts =
        JSON.parse(JSON.stringify(this.bulkPricingInSelectedUom)) ?? [];

      const sorted = discounts.sort(
        (a, b) => a.quantityBreak - b.quantityBreak
      );

      let biggestDiscount;

      for (let i = 0; i < sorted.length; i++) {
        if (this.currentQty >= sorted[i].quantityBreak) {
          if (
            !biggestDiscount ||
            biggestDiscount.unitPriceFromBulkBuy >=
              sorted[i].unitPriceFromBulkBuy
          ) {
            biggestDiscount = sorted[i];
          }
        }
      }

      if (biggestDiscount) {
        return {
          price: biggestDiscount.unitPriceFromBulkBuy,
          quantityBreak: biggestDiscount.quantityBreak,
        };
      }

      return undefined;
    },
    hasVolumeDiscount() {
      return this.findBiggestVolumeDiscount !== undefined;
    },
    basePrice() {
      if (this.hasBasePricing) {
        return this.formatPrice(this.basePricing.skuRetailIncGst);
      }

      return '';
    },
    lowestPrice() {
      if (this.hasLowestPrice) {
        return this.formatPrice(this.basePricing.webProductLowest.price);
      }

      return '';
    },
    hasDiscountedPrice() {
      if (this.cheapestBasePrice > this.cheapestDiscountPrice) {
        return true;
      }

      return false;
    },
    hasCurrentQty() {
      return this.currentQty > 0;
    },
    displayUom() {
      // find sku of current price (base or lowest)
      let sku = this.hasLowestPrice
        ? this.basePricing.webProductLowest.sku
        : this.sku;

      const uom = this.findSkuUom(sku);
      return uom ? this.$uom(uom, 'productCard', 1, {}) : '';
    },
    cheapestBasePrice() {
      // when user is logged in, show the cheapest base/discount prices from the following list:
      // 1. clearance promotion price plus gst
      // 2. Logged In User's custom price
      // 3. Volume Discount Unit Price, if exists
      const clearance = this.basePricing?.unitPrice?.plusGst ?? undefined;
      const custom = this.customPricing?.baseUnitPrice ?? undefined;

      // compare the 2numbers. if not defined ignore. if equals 0 ignore. return the lowest
      const numbers = [clearance, custom]
        .filter(num => num !== 0)
        .filter(num => num !== undefined);
      if (numbers.length === 0) {
        return undefined;
      }

      return Math.min(...numbers);
    },
    cheapestDiscountPrice() {
      // when user is logged in, show the cheapest base/discount prices from the following list:
      // 1. clearance promotion price plus gst
      // 2. Logged In User's custom price
      // 3. Volume Discount Unit Price, if exists
      const clearance = this.basePricing?.promotion?.plusGst ?? undefined;
      const custom = this.customPricing?.unitPrice ?? undefined;
      let volume = 0;

      const validDiscount = this.findBiggestVolumeDiscount;
      if (validDiscount) {
        if (
          this.isValidPriceNumber(validDiscount.price) &&
          this.currentQty >= validDiscount.quantityBreak
        ) {
          volume = validDiscount.price;
        }
      }

      // compare the 3 numbers. if not defined ignore. if equals 0 ignore. return the lowest
      const numbers = [clearance, custom, volume]
        .filter(num => num !== 0)
        .filter(num => num !== undefined);
      if (numbers.length === 0) {
        return undefined;
      }

      return Math.min(...numbers);
    },
    bulkPricingInSelectedUom() {
      // if user has not selected a UoM, return the pricing
      // in its orginal UoM
      if (!this.selectedUom) {
        return this.basePricing?.volumeDiscounts;
      }

      const selectedUom = this.selectedUom;
      const volumeDiscounts = this.basePricing?.volumeDiscounts;
      // convert the bulk buy pricing to the selected UOM
      const converted = volumeDiscounts.map(pricing => {
        const { quantityBreak, unitPriceFromBulkBuy } = pricing;

        return {
          ...pricing,
          uom: selectedUom.uom,
          conversionFactor: selectedUom.conversionFactor,
          quantityBreak: Math.ceil(
            quantityBreak / selectedUom.conversionFactor
          ),
          unitPriceFromBulkBuy,
        };
      });

      return converted;
    },
  },
  methods: {
    isValidPriceNumber(value) {
      return value && typeof value === 'number' && value > 0;
    },
    formatPrice(value) {
      let price = value;
      if (this.selectedUom && this.selectedUom.conversionFactor > 0) {
        price = value * this.selectedUom.conversionFactor;
      }

      return `$${price.toFixed(2)}`;
    },
    findSkuUom(sku) {
      return this.priceQuery.find(item => item.sku === sku)?.uom || '';
    },
  },
};
